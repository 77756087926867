export default {
  settings: {
    dialog: {
      maxWidth: "1000px",
      mediumMaxWidth: "713px",
      smallMaxWidth: "600px"
    }
  },
  messages: {
    unidentifiedProblemHead: "Wystąpił błąd",
    unidentifiedProblem:
      "Wystąpił niezidentyfikowany problem. Zgłoś błąd do administracji."
  },
}
